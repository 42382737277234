import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';
import i18n from './i18n'; // 假设您的 i18n 配置文件在这个位置
// import SubmitPrompt from './views/SubmitPrompt.vue';
import GenVideo from './views/GenVideoWithServer.vue';
import InpaintPage from './views/InpaintWithServer.vue';
import BigBoobPage from './views/BigBoobWithServer.vue';
import HairColored from './views/HairColoredWithServer.vue';
import GenImage from './views/GenImageWithServer.vue';
// import GenSD3Image from './views/GenSD3ImageWithServer.vue';
import CustomCanvas from './views/CustomCanvas.vue';
import ImgStyleTransfor from './views/ImgStyleTransforWithServer.vue';  
import ImgEnhancer from './views/ImgEnhancerWithServer.vue';
import RemoveWaterMark from './views/RemoveWaterMark.vue';

const localeRoutes = [
    {
        path: 'video',
        name: 'VideoGen',
        component: GenVideo,
        meta: {
            title: {
                en: 'AI Video Generation',
                zh: 'AI视频生成',
                ja: 'AIビデオ生成'
            },
            description: {
                en: 'Generate custom videos with our advanced AI tool. Perfect for artists and designers looking to create unique animations.',
                zh: '使用我们的高级AI工具生成定制视频。适合希望创建独特动画的艺术家和设计师。',
                ja: '高度なAIツールを使用してカスタムビデオを生成します。ユニークなアニメーションを作成したいアーティストやデザイナーに最適です。'
            },
            keywords: {
                en: 'AI, video, generation, custom, artists, designers, animations',
                zh: 'AI, 视频, 生成, 定制, 艺术家, 设计师, 动画',
                ja: 'AI, ビデオ, 生成, カスタム, アーティスト, デザイナー, アニメーション'
            }
        }
    },
    {
        path: 'inpaint',
        name: 'Inpaint',
        component: InpaintPage,
        meta: {
            title: {
                en: 'Image Inpainting',
                zh: '图像修复',
                ja: '画像修復'
            },
            description: {
                en: 'Remove unwanted objects from your images with our advanced AI tool. Perfect for photographers and designers.',
                zh: '使用我们的高级AI工具从图像中删除不需要的对象。摄影师和设计师的完美选择。',
                ja: '高度なAIツールを使用して画像から不要なオブジェクトを削除します。写真家やデザイナーに最適です。'
            },
            keywords: {
                en: 'AI, inpainting, remove, objects, photos, photographers, designers',
                zh: 'AI, 修复, 移除, 对象, 照片, 摄影师, 设计师',
                ja: 'AI, 修復, 削除, オブジェクト, 写真, 写真家, デザイナー'
            }
        }
    },
    {
        path: 'bigboob',
        name: 'BigBoob',
        component: BigBoobPage,
        meta: {
            title: {
                en: 'Big Boob Generation',
                zh: 'AI一键丰胸',
                ja: 'AIバストアップ',
            },
            description: {
                en: 'Generate big boobs with our advanced AI tool. Perfect for people looking to enhance their photos.',
                zh: '使用我们的高级AI工具生成大胸部。适合希望增强照片的人。',
                ja: '高度なAIツールを使用して大きな胸を生成します。写真を強化したい人に最適です。'
            },
            keywords: {
                en: 'AI, big boob, generation, enhance, photos',
                zh: 'AI, 大胸部, 生成, 增强, 照片',
                ja: 'AI, 大きな胸, 生成, 強化, 写真'
            }
        }
    },
    {
        path: 'hair',
        name: 'HairColored',
        component: HairColored,
        meta: {
            title: {
                en: 'AI Hair Coloring',
                zh: 'AI试染发',
                ja: 'AIヘアカラリング'
            },
            description: {
                en: 'Try out different hair colors with our advanced AI tool. Perfect for people looking to experiment with new hair colors.',
                zh: '使用我们的高级AI工具尝试不同的发色。适合希望尝试新发色的人。',
                ja: '高度なAIツールを使用してさまざまな髪の色を試してみてください。新しい髪色を試してみたい人に最適です。'
            },
            keywords: {
                en: 'AI, hair, coloring, try, experiment, colors',
                zh: 'AI, 试染发, 尝试, 实验, 颜色',
                ja: 'AI, ヘア, カラリング, 試す, 実験, 色'
            }
        }
    },
    {
        path: 'aiimg',
        name: 'GenImage',
        component: GenImage,
        meta: {
            title: {
                en: 'AI Image Generation',
                zh: 'AI绘图',
                ja: 'AI画像生成'
            },
            description: {
                en: 'Generate custom images with our advanced AI tool. Perfect for artists and designers looking to create unique artworks.',
                zh: '使用我们的高级AI工具生成定制图像。适合希望创建独特艺术品的艺术家和设计师。',
                ja: '高度なAIツールを使用してカスタム画像を生成します。ユニークなアートワークを作成したいアーティストやデザイナーに最適です。'
            },
            keywords: {
                en: 'AI, image, generation, custom, artists, designers, artworks',
                zh: 'AI, 图像, 生成, 定制, 艺术家, 设计师, 艺术品',
                ja: 'AI, 画像, 生成, カスタム, アーティスト, デザイナー, アートワーク'
            }
        }
    },
    {
        path: 'bg',
        name: 'GenScreenBackground',
        component: CustomCanvas,
        meta: {
            title: {
                en: 'Wallpaper Generation',
                zh: '壁纸生成',
                ja: '壁紙生成'
            },
            description: {
                en: 'Generate custom wallpapers with our advanced AI tool. Perfect for artists and designers looking to create unique backgrounds.',
                zh: '使用我们的高级AI工具生成定制壁纸。适合希望创建独特背景的艺术家和设计师。',
                ja: '高度なAIツールを使用してカスタム壁紙を生成します。ユニークな背景を作成したいアーティストやデザイナーに最適です。'
            },
            keywords: {
                en: 'wallpaper, generation, custom, AI tool, artists, designers, backgrounds',
                zh: '壁纸, 生成, 定制, AI工具, 艺术家, 设计师, 背景',
                ja: '壁紙, 生成, カスタム, AIツール, アーティスト, デザイナー, 背景'
            }
        }
    },
    {
        path: 'style',
        name: 'ImgStyleTransfor',
        component: ImgStyleTransfor,
        meta: {
            title: {
                en: 'Image Style Transfer - Transform Your Photos',
                zh: '图像风格转换 - 改变您的照片',
                ja: '画像スタイル変換 - あなたの写真を変える'
            },
            description: {
                en: 'Effortlessly transform your photos with our advanced image style transfer tool. Perfect for artists and designers looking to apply unique styles.',
                zh: '使用我们的高级图像风格转换工具轻松改变您的照片。适合希望应用独特风格的艺术家和设计师。',
                ja: '高度な画像スタイル変換ツールを使用して、簡単に写真を変換します。ユニークなスタイルを適用したいアーティストやデザイナーに最適です。'
            },
            keywords: {
                en: 'image, style transfer, photo transformation, artistic styles, photo editing, image enhancement',
                zh: '图像, 风格转换, 照片转换, 艺术风格, 照片编辑, 图像增强',
                ja: '画像, スタイル変換, 写真変換, アートスタイル, 写真編集, 画像強化'
            }
        }
    },
    {
        path: 'enhancer',
        name: 'ImgEnhancer',
        component: ImgEnhancer,
        meta: {
            title: {
                en: 'Image Enhancer & Image Upscaler',
                zh: '图像超清放大'
            },
            description: {
                en: 'Enhance and upscale your images effortlessly with our advanced image enhancer tool. Perfect for photographers and designers.',
                zh: '使用我们的高级图像增强工具轻松增强和放大您的图像。摄影师和设计师的完美选择。'
            },
            keywords: {
                en: 'image, enhancer, tool, upscaler, photo editing, image enhancement, image upscaling',
                zh: '图像, 增强, 工具, 放大, 照片编辑, 图像增强, 图像放大'
            }
        }
    },
    {
        path: 'watermark',
        name: 'RemoveWaterMark',
        component: RemoveWaterMark,
        meta: {
            title: {
                en: 'Watermark Remover',
                zh: '水印去除'
            },
            description: {
                en: 'Remove watermarks from your images effortlessly with our advanced watermark remover tool. Perfect for photographers and designers.',
                zh: '使用我们的高级水印去除工具轻松去除您的图像中的水印。摄影师和设计师的完美选择。'
            },
            keywords: {
                en: 'watermark, remover, tool, photo editing, image enhancement, watermark removal',
                zh: '水印, 去除, 工具, 照片编辑, 图像增强, 水印移除'
            }
        }
    },
    {
        path: ':pathMatch(.*)*',
        redirect: 'bigboob',
        meta: {
            title: {
                en: 'Redirect',
                zh: '重定向'
            }
        }
    }
];

// 检测浏览器语言
// const getBrowserLanguage = () => {
//     const lang = navigator.language || navigator.userLanguage;
//     return lang.startsWith('zh') ? 'zh' : 'en';
// };

// 动态生成 alternateLangs
localeRoutes.forEach(route => {
    if (!route.meta) route.meta = {};
    const baseUrl = window.location.origin;
    route.meta.alternateLangs = {
        en: `${baseUrl}/en/${route.path}`,
        zh: `${baseUrl}/${route.path}`,
        ja: `${baseUrl}/ja/${route.path}`
    };
});

const routes = [
    {
        path: '/:lang(en|zh|ja)?',
        children: localeRoutes,
        beforeEnter: (to, from, next) => {
            const lang = to.params.lang || 'zh';
            i18n.global.locale.value = lang;
            document.querySelector('html').setAttribute('lang', lang);
            if (lang === 'zh' && to.path.startsWith('/zh')) {
                return next(to.path.replace('/zh', ''));
            }
            return next();
        }
    },
];

if (process.env.VUE_APP_DEFAULT_PAGE) {
    routes[0].children.find(route => route.path === '').redirect = process.env.VUE_APP_DEFAULT_PAGE;
    routes[0].children.find(route => route.path === ':pathMatch(.*)*').redirect = process.env.VUE_APP_DEFAULT_PAGE;
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const defaultTitle = {
    en: 'Magic Photo',
    zh: '影像魔术师',
    ja: '魔法の写真'
};

router.afterEach((to) => {
    nextTick(() => {
        const lang = i18n.global.locale.value;

        // 更新页面标题
        if (to.meta.title && to.meta.title[lang]) {
            document.title = defaultTitle[lang] + " - " + to.meta.title[lang];
        }

        // 更新 meta description
        let descriptionTag = document.querySelector('meta[name="description"]');
        if (!descriptionTag) {
            descriptionTag = document.createElement('meta');
            descriptionTag.name = 'description';
            document.head.appendChild(descriptionTag);
        }
        if (to.meta.description && to.meta.description[lang]) {
            descriptionTag.content = to.meta.description[lang];
        }

        // 更新 meta keywords
        let keywordsTag = document.querySelector('meta[name="keywords"]');
        if (!keywordsTag) {
            keywordsTag = document.createElement('meta');
            keywordsTag.name = 'keywords';
            document.head.appendChild(keywordsTag);
        }
        if (to.meta.keywords && to.meta.keywords[lang]) {
            keywordsTag.content = to.meta.keywords[lang];
        }

        // 更新 alternateLangs
        const linkTags = document.querySelectorAll('link[rel="alternate"]');
        linkTags.forEach(tag => tag.parentNode.removeChild(tag));

        if (to.meta.alternateLangs) {
            Object.keys(to.meta.alternateLangs).forEach(lang => {
                const link = document.createElement('link');
                link.rel = 'alternate';
                link.hreflang = lang;
                link.href = `${window.location.origin}${to.meta.alternateLangs[lang]}`;
                document.head.appendChild(link);
            });
        }
    });
});

export default router;
