<template>
    <div class="_n3cd6gd7b5" style="min-width: 300px; min-height: 250px;"></div>
</template>

<script>
import { onMounted } from 'vue';

export default {
    setup() {
        onMounted(() => {
            // 动态加载广告脚本
            const script1 = document.createElement('script');
            script1.type = 'text/javascript';
            script1.innerHTML = `(window.slotbydup = window.slotbydup || []).push({
                id: "u6986886",
                container: "_n3cd6gd7b5",
                async: true
            });`;
            document.body.appendChild(script1);

            const script2 = document.createElement('script');
            script2.type = 'text/javascript';
            script2.src = "//cpro.baidustatic.com/cpro/ui/cm.js";
            script2.async = true;
            script2.defer = true;
            document.body.appendChild(script2);
        });
    }
}
</script>
