<template>
    <div>
        <p>🥇：{{ Math.floor(coinCount) }}</p>
    </div>
</template>

<script setup>
import { ref, inject, watch } from 'vue';
import axios from 'axios';

const coinCount = ref(0); // 使用ref创建响应式数据

const getCoinCount = async () => {
    try {
        const getCoinURL = process.env.VUE_APP_BASE_URL + '/pay/coin?timestamp=' + Date.now();
        const response = await axios.get(getCoinURL, {
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        
        });
        coinCount.value = response.data.coin; // 假设返回的数据中有coinCount字段
        user.coin = response.data.coin;
    } catch (error) {
        console.error('获取数据失败:', error);
    }
}

const user = inject("user");
watch(user, (newUser) => {
    if (newUser.isLogin) {
        getCoinCount();
    }
});

getCoinCount();
setTimeout(() => {
    getCoinCount();
}, 2000); // 2s后刷新一次
</script>

<style scoped>
h1 {
    color: #2c3e50;
}

p {
    font-size: 18px;
}
</style>